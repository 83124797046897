import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AccountService } from '../account.service';
import { HostnameService } from '../hostname.service';
import { convertFormStaffToFormData } from './staff.form.service.utils';

@Injectable({ providedIn: 'root' })
export class StaffFormService {
    private _data: any;

    private _dataSub: Subject<any> = new Subject();

    public get data(): any {
        return this._data;
    }
    public set data(value: any) {
        this._data = value;
        this._dataSub.next(this._data);
    }
    public get dataSub(): Subject<any> {
        return this._dataSub;
    }

    constructor(
        private httpClient: HttpClient,
        private hostnameService: HostnameService,
        private accountService: AccountService
    ) {}

    public getFormData() {
        return convertFormStaffToFormData(this._data);
    }

    async fetch(ma_don_vi: any = undefined, ma_cb: any = undefined) {
        if (!ma_don_vi) ma_don_vi = this.accountService.getMetadata().ma_don_vi;
        if (!ma_cb) ma_cb = this.accountService.getMetadata().ma_cb;

        let result: any = await this.httpClient
            .post<any>(
                this.hostnameService.VnuisAPI_Readv1 + '/staff/get-by-ma-cb',
                {
                    metadata: {
                        ma_don_vi: ma_don_vi,
                    },
                    ma_cb: ma_cb,
                },
                {
                    headers: { apikey: this.accountService.getAPIKey() },
                }
            )
            .toPromise();

        if (result == 'FAIL') {
            this.data = undefined;
            return this.data;
        }

        this.data = result;
        return this.data;
    }

    async fetchIfNotExists() {
        if (!this.data || Object.keys(this.data).length <= 0) {
            return await this.fetch();
        }
        return this.data;
    }
}
