<div style="display: flex; align-items: center">
    <button [matMenuTriggerFor]="flags" mat-icon-button class="m-r-5">
        <i class="flag-icon flag-icon-{{ selectedLanguage.icon }}"> </i>
    </button>
    <mat-menu #flags="matMenu" class="mymegamenu">
        <button mat-menu-item *ngFor="let lang of languages" (click)="changeLanguage(lang)">
            <i class="flag-icon flag-icon-{{ lang.icon }} m-r-10"></i>
            <span>{{ lang.language }}</span>
        </button>
    </mat-menu>

    <button
        [matMenuTriggerFor]="profile"
        class="m-r-5 username-container"
        style="display: flex; align-items: center; justify-content: center"
        mat-ripple
    >
        <img [src]="avatarLink" alt="user" class="profile-pic" />

        <div class="userInfor">
            <div class="username">{{ username }}</div>
            <div class="role">Staff</div>
        </div>
    </button>
    <mat-menu #profile="matMenu" class="mymegamenu">
        <button mat-menu-item (click)="accountInfo()">
            <mat-icon>account_box</mat-icon> {{ 'vertical_header.thong_tin_tai_khoan' | translate }}
        </button>
        <button mat-menu-item (click)="logout()">
            <mat-icon>exit_to_app</mat-icon> {{ 'vertical_header.dang_xuat' | translate }}
        </button>
    </mat-menu>
</div>
