import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AccountService } from './account.service';
import { FileService } from './file.service';
import { HostnameService } from './hostname.service';

@Injectable({ providedIn: 'root' })
export class AccountAvatarService {
    private _avatarLink: string = '';
    private _avatarLinkSub: Subject<string> = new Subject<string>();

    public get AvatarLink() {
        return this._avatarLink;
    }

    public set AvatarLink(value: string) {
        this._avatarLink = value;
        this._avatarLinkSub.next(value);
    }

    public get AvatarLinkSub() {
        return this._avatarLinkSub;
    }

    constructor(
        private httpClient: HttpClient,
        private hostnameService: HostnameService,
        protected readonly keycloak: KeycloakService,
        private accountService: AccountService,
        private fileService: FileService
    ) {}

    async updateAvatarLink() {
        this._avatarLink = '/assets/images/users/default.png';
        let avatar = this.accountService.getMetadata().avatar;
        if (avatar) {
            let presignedResult: any = await this.fileService.getPresignded(avatar, 'images', 5);
            if (presignedResult != 'FAIL') this._avatarLink = presignedResult;
        }
        this._avatarLinkSub.next(this._avatarLink);
    }
}
