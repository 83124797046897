import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { createMapingDLHC } from '../utils/general-data.utils';
import { AccountService } from './account.service';
import { HostnameService } from './hostname.service';

@Injectable({ providedIn: 'root' })
export class GeneralDataService {
    private _data: any = {};
    private _dataSub: Subject<any> = new Subject<any>();

    private _isLoading: boolean = false;
    private _isLoadingSub: Subject<boolean> = new Subject<boolean>();

    public get data(): any {
        return this._data;
    }
    public set data(value: any) {
        this._data = value;
        this._dataSub.next(this._data);
    }
    public get dataSub(): Subject<any> {
        return this._dataSub;
    }

    public get isLoading(): boolean {
        return this._isLoading;
    }
    public set isLoading(value: boolean) {
        this._isLoading = value;
        this._isLoadingSub.next(this._isLoading);
    }
    public get isLoadingSub(): Subject<boolean> {
        return this._isLoadingSub;
    }

    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService
    ) {}

    async waitLoading() {
        if (!this._isLoading) return Promise.resolve();

        return new Promise<void>((rel, rej) => {
            this._isLoadingSub.subscribe((data: any) => {
                if (!this._isLoading) rel();
            });
        });
    }

    async fetchAll() {
        return this.httpClient
            .get<any>(this.hostnameService.VnuisAPI_Readv1 + '/general-data/get-all', {
                headers: {
                    apikey: this.accountService.getAPIKey(),
                },
            })
            .toPromise();
    }

    async fetchAllByType(type: string) {
        return this.httpClient
            .get<any>(this.hostnameService.VnuisAPI_Readv1 + '/general-data/get-all-by-type?type=' + type, {
                headers: {
                    apikey: this.accountService.getAPIKey(),
                },
            })
            .toPromise();
    }

    async fetch() {
        let data = {};

        let results = await Promise.all([
            this.fetchAll(),
            this.httpClient
                .get<any>(this.hostnameService.VnuisAPI_Readv1 + '/general-data/get-all-dlhc', {
                    headers: {
                        apikey: this.accountService.getAPIKey(),
                    },
                })
                .toPromise(),
        ]);

        // general data
        let generalData = results[0];
        if (generalData != 'FAIL') data = { ...data, ...generalData };

        //du lieu dia li hanh chinh
        let dlhc: any = results[1];
        if (dlhc != 'FAIL') {
            data = {
                ...data,
                ...dlhc,
                dlhc_phuong_xa: createMapingDLHC(dlhc.phuong_xa),
                dlhc_quan_huyen: createMapingDLHC(dlhc.quan_huyen),
                dlhc_tinh_thanh: createMapingDLHC(dlhc.tinh_thanh),
                code_px_from_code_tt_qh: dlhc.code_px_from_code_tt_qh,
                code_qh_from_code_tt: dlhc.code_qh_from_code_tt,
            };
        }

        this.data = data;
    }

    getDataByCode(ma: string, type: string, mainKey: string = 'ma') {
        if (type in this._data) {
            let data = this._data[type];
            for (let row of data) if (row[mainKey] == ma) return row;
        }

        return undefined;
    }

    getByType(type: string) {
        return this._data && this._data[type] ? this._data[type] : [];
    }
}
