// CONSTABCE
export const MCODES = {
    dashboard: 'dashboard', // Bảng điều khiển
    luutru: 'luutru', // Lưu trữ
    tienich: 'tienich', // Tiện ích
    taikhoan: 'taikhoan', // Tài khoản

    thong_tin_ca_nhan: 'thong_tin_ca_nhan',
    khoa_hoc_cong_nghe: 'khoa_hoc_cong_nghe',
    hoat_dong_hop_tac: 'hoat_dong_hop_tac',
};

// ROLES

// prettier-ignore
export const MENU_ITEMS_ROLES: Map<string, string[]> = new Map([
    ['admin', [
        MCODES.dashboard, MCODES.luutru, MCODES.taikhoan, 
        MCODES.thong_tin_ca_nhan, MCODES.khoa_hoc_cong_nghe, MCODES.hoat_dong_hop_tac,
        MCODES.tienich, 
    ]],

    // CAN_BO
    ['vnuhcm.portal.staff', [
        MCODES.dashboard, MCODES.luutru, MCODES.taikhoan, 
        MCODES.thong_tin_ca_nhan, MCODES.khoa_hoc_cong_nghe, MCODES.hoat_dong_hop_tac,
        MCODES.tienich, 
    ]],
    
]);

// FUNCTIONS
export function menu_items_check_for_role(roles: string[]) {
    let menu_items: Set<string> = new Set();
    roles.forEach((role: string) => {
        let menu_for_role = MENU_ITEMS_ROLES.get(role);
        if (!Array.isArray(menu_for_role)) return;
        menu_for_role.forEach((item: string) => menu_items.add(item));
    });
    return [...menu_items];
}
