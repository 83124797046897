import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AccountService } from '../account.service';
import { HostnameService } from '../hostname.service';
import { OrgDataService } from '../org-data.service';

@Injectable({ providedIn: 'root' })
export class ThongTinDongBoService {
    private _data: any[] = [];
    private _dataSub: Subject<any[]> = new Subject<any[]>();

    public get data(): any[] {
        if (!Array.isArray(this._data)) return [];
        return this._data;
    }
    public set data(value: any[]) {
        this._data = value;
        this._dataSub.next(this._data);
    }
    public get dataSub(): Subject<any[]> {
        return this._dataSub;
    }

    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService,
        private orgDataService: OrgDataService
    ) {}

    async fetch(cmnd_cccd: string, phone: string, ma_so_thue: string) {
        let metadata = this.accountService.getMetadata();
        let apikey = this.accountService.getAPIKey();
        let ttdb = await this.httpClient
            .post<any>(
                this.hostnameService.VnuisAPI + '/api/v1/read/staff-sync/get-tttc-codes',
                {
                    metadata: {
                        ma_don_vi: metadata.ma_don_vi,
                        mat_khau_don_vi: metadata.mat_khau_don_vi,
                    },
                    cmnd_cccd: cmnd_cccd,
                    phone: phone,
                    ma_so_thue: ma_so_thue,
                },
                {
                    headers: { apikey: apikey },
                }
            )
            .toPromise();

        if (!ttdb || ttdb == 'FAIL' || ttdb.status == 'error') {
            this.data = [];
        } else {
            this.data = ttdb;
        }

        return this.data;
    }

    async getTtdbCodes(cmnd_cccd: string | undefined, phone: string | undefined, ma_so_thue: string | undefined) {
        let metadata = this.accountService.getMetadata();
        let apikey = this.accountService.getAPIKey();
        return this.httpClient
            .post<any>(
                this.hostnameService.VnuisAPI_Readv1 + '/staff-sync/get-tttc-codes',
                {
                    metadata: {
                        ma_don_vi: metadata.ma_don_vi,
                        mat_khau_don_vi: metadata.mat_khau_don_vi,
                    },
                    cmnd_cccd: cmnd_cccd,
                    phone: phone,
                    ma_so_thue: ma_so_thue,
                },
                {
                    headers: { apikey: apikey },
                }
            )
            .toPromise();
    }

    async getIdSyncsInfo(id_syncs: string[]) {
        let metadata = this.accountService.getMetadata();
        let apikey = this.accountService.getAPIKey();
        return this.httpClient
            .post<any>(
                this.hostnameService.VnuisAPI + '/api/v1/read/staff-sync/get-id-syncs-info',
                {
                    metadata: {
                        ma_don_vi: metadata.ma_don_vi,
                    },
                    id_syncs: id_syncs,
                },
                {
                    headers: { apikey: apikey },
                }
            )
            .toPromise();
    }

    async sync(
        ma_don_vi: string | undefined,
        cmnd_cccd: string | undefined,
        phone: string | undefined,
        ma_so_thue: string | undefined
    ) {
        if (!ma_don_vi || !cmnd_cccd || !phone || !ma_don_vi) {
            return new Promise<any>((resolve: any) => resolve({ status: 'error', msg: 'input is not valid' }));
        }

        let metadata = this.accountService.getMetadata();
        return this.httpClient
            .post<any>(
                this.hostnameService.VnuisAPI_Stagingv1 + '/staff-sync/sync',
                {
                    metadata: {
                        ma_don_vi: metadata.ma_don_vi,
                    },
                    ma_don_vi: ma_don_vi,
                    cmnd_cccd: cmnd_cccd,
                    phone: phone,
                    ma_so_thue: ma_so_thue,
                },
                {
                    headers: { apikey: this.accountService.getAPIKey() },
                }
            )
            .toPromise();
    }
}
