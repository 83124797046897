function convertFormStaffToFormDataArray(formStaffData: any, formData: FormData, mainKey: string, innerKey: string) {
    let keys: any[] = [];
    if (!Array.isArray(formStaffData.data[mainKey])) return;
    formStaffData.data[mainKey].forEach((quaTrinh: any, quaTrinhIndex: number) => {
        if (!Array.isArray(quaTrinh[innerKey])) return;
        quaTrinh[innerKey].forEach((fileInfo: any, fileIndex: number) => {
            if (fileInfo.isNew) {
                let key: string = `${mainKey}_${quaTrinhIndex}_${fileIndex}`;
                keys.push({ qti: quaTrinhIndex, fi: fileIndex, key: key });
                formData.set(key, fileInfo.file);
            }
        });
    });
    if (keys.length > 0) formData.set(`${mainKey}_keys`, JSON.stringify(keys));
}

export function convertFormStaffToFormData(formStaffData: any) {
    let formData: FormData = new FormData();

    formStaffData['is_staff_edit'] = true;
    formData.set('dataJson', JSON.stringify(formStaffData));

    // CMND_CCCD
    if (formStaffData.data.cmnd_cccd) {
        let cmnd_cccd: any = formStaffData.data.cmnd_cccd;
        if (cmnd_cccd.mat_truoc?.isNew) formData.set('mat_truoc', cmnd_cccd.mat_truoc.file);
        if (cmnd_cccd.mat_sau?.isNew) formData.set('mat_sau', cmnd_cccd.mat_sau.file);
    }

    // QUA_TRINH_DAO_TAO_HOC_VI
    convertFormStaffToFormDataArray(formStaffData, formData, 'qua_trinh_dao_tao_hoc_vi', 'minh_chung');

    // TRINH_DO_NGOAI_NGU
    convertFormStaffToFormDataArray(formStaffData, formData, 'trinh_do_ngoai_ngu', 'file');

    // TRINH_DO_TIN_HOC
    convertFormStaffToFormDataArray(formStaffData, formData, 'trinh_do_tin_hoc', 'file');

    // QUA_TRINH_BOI_DUONG
    convertFormStaffToFormDataArray(formStaffData, formData, 'qua_trinh_boi_duong', 'file');

    // KHEN_THUONG
    convertFormStaffToFormDataArray(formStaffData, formData, 'khen_thuong', 'file');

    // KY_LUAT
    convertFormStaffToFormDataArray(formStaffData, formData, 'ky_luat', 'file');

    // TAI_LIEU
    convertFormStaffToFormDataArray(formStaffData, formData, 'tai_lieu', 'file');

    return formData;
}
