import { MCODES } from './roles.menu.utils';

export const MENU_ITEMS_DASHBOARD = {
    state: 'dashboards',
    name: 'menuitems.dashboard',
    type: 'link',
    icon: 'av_timer',
};

export const MENU_ITEMS_TAIKHOAN = {
    state: 'account',
    name: 'menuitems.tai_khoan',
    type: 'sub',
    icon: 'manage_accounts',
    children: [{ state: 'account-info', name: 'menuitems.account_info', type: 'link' }],
};

export const MENU_ITEMS_LUUTRU = {
    state: 'storage',
    name: 'menuitems.luu_tru',
    type: 'sub',
    icon: 'archive',
    children: [{ state: 'files-downloaded', name: 'menuitems.file_downloaded', type: 'link' }],
};

export const MENU_ITEMS_TIENICH = {
    state: 'tien-ich-syll',
    name: 'menuitems.tien_ich',
    type: 'sub',
    icon: 'extension',
    children: [
        { state: 'syll-khoa-hoc', name: 'menuitems.tien_ich_syll_khoa_hoc', type: 'link' },
        { state: 'syll-chi-tiet', name: 'menuitems.tien_ich_syll_chi_tiet', type: 'link' },
    ],
};

export const MENU_ITEMS_THONG_TIN_CA_NHAN = {
    state: 'thong_tin_ca_nhan',
    name: 'menuitems.thong_tin_ca_nhan',
    type: 'sub',
    icon: 'library_books',
    children: [
        { state: 'thong_tin_co_ban', name: 'menuitems.thong_tin_co_ban', type: 'link' },
        { state: 'qua_trinh_dao_tao_hoc_vi', name: 'menuitems.qua_trinh_dao_tao_hoc_vi', type: 'link' },
        { state: 'qua_trinh_lam_viec', name: 'menuitems.qua_trinh_lam_viec', type: 'link' },
        { state: 'hoc_ham_chuc_danh', name: 'menuitems.hoc_ham_chuc_danh', type: 'link' },
        { state: 'khen_thuong_ky_luat', name: 'menuitems.khen_thuong_ky_luat', type: 'link' },
        { state: 'dao_tao_boi_duong', name: 'menuitems.dao_tao_boi_duong', type: 'link' },
        { state: 'hop_dong_lao_dong', name: 'menuitems.hop_dong_lao_dong', type: 'link' },
        { state: 'thong_tin_khac', name: 'menuitems.thong_tin_khac', type: 'link' },
    ],
};

export const MENU_ITEMS_KHOA_HOC_CONG_NGHE = {
    state: 'khoa_hoc_cong_nghe',
    name: 'menuitems.khoa_hoc_cong_nghe',
    type: 'sub',
    icon: 'fingerprint',
    children: [
        { state: 'chuyen_mon_giai_thuong_ca_nhan', name: 'menuitems.chuyen_mon_giai_thuong_ca_nhan', type: 'link' },
        { state: 'hoat_dong_nghien_cuu', name: 'menuitems.hoat_dong_nghien_cuu', type: 'link' },
        { state: 'bien_tap_xuat_ban', name: 'menuitems.bien_tap_xuat_ban', type: 'link' },
        { state: 'sang_che_chuyen_giao', name: 'menuitems.sang_che_chuyen_giao', type: 'link' },
        { state: 'hoat_dong_dao_tao', name: 'menuitems.hoat_dong_dao_tao', type: 'link' },
    ],
};

export const MENU_ITEMS_HOAT_DONG_HOP_TAC = {
    state: 'hoat_dong_hop_tac',
    name: 'menuitems.hoat_dong_hop_tac',
    type: 'link',
    icon: 'people',
};

export const MENU_ITEMS: Map<string, any> = new Map<string, any>([
    [MCODES.dashboard, MENU_ITEMS_DASHBOARD],
    [MCODES.thong_tin_ca_nhan, MENU_ITEMS_THONG_TIN_CA_NHAN],
    [MCODES.khoa_hoc_cong_nghe, MENU_ITEMS_KHOA_HOC_CONG_NGHE],
    [MCODES.hoat_dong_hop_tac, MENU_ITEMS_HOAT_DONG_HOP_TAC],
    [MCODES.luutru, MENU_ITEMS_LUUTRU],
    [MCODES.tienich, MENU_ITEMS_TIENICH],
    [MCODES.taikhoan, MENU_ITEMS_TAIKHOAN],
]);

export const MENU_ITEMS_ORDER: string[] = [
    MCODES.dashboard,
    MCODES.thong_tin_ca_nhan,
    MCODES.khoa_hoc_cong_nghe,
    MCODES.hoat_dong_hop_tac,
    MCODES.tienich,
    MCODES.luutru,
    MCODES.taikhoan,
];
